module controllers {
    'use strict';
    export module reporting {
        interface IcertificateFromRegisterScope extends ng.IScope { }

        interface IcertificateFromRegisterParams extends ng.ui.IStateParamsService {
            consignmentId: number;
            reportId: number;
        }

        export class certificateFromRegisterCtrl {
            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                "$anchorScroll",
                "$state",
                "bsLoadingOverlayService",
                "lookupService",
                "$timeout",
                '$transitions',
                'reportService'];

            consignmentId: number;
            reportId: number;
            selectedCertificateNumber: interfaces.applicationcore.IDropdownModel;
            currentReport: interfaces.reporting.IReport;
            previewOnly: boolean;
            searchAccordian: boolean = true;
            loadPromises: ng.IPromise<any>[];

            constructor(
                private $scope: IcertificateFromRegisterScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: IcertificateFromRegisterParams,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,               
                public bsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private reportService: interfaces.reporting.IReportService)
            {
                this.loadPromises = [];
                this.consignmentId = $stateParams.consignmentId;
                this.reportId = $stateParams.reportId;

                this.loadPromises.push(this.loadReportDetails());
                
                bsLoadingOverlayService.wrap({
                    referenceId: 'certificate.print'
                }, () => {
                    return $q.all(this.loadPromises).then(() => {
                        this.$timeout(() => {
                            
                        });
                    });
                });
            }

            loadReportDetails() {
                return this.reportService.GetReportDetail(this.reportId).get((data: interfaces.reporting.IReport) => {
                    this.currentReport = data;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            getCertificateNumbers() {
                var defered = this.$q.defer();

                this.reportService.getCertificateNumbers(this.consignmentId, this.reportId).query((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            CertificateChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.selectedCertificateNumber = model;
                }
            }


            Print() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'certificate.print'
                },
                    () => {

                        this.reportService.saveCertificateToDocument(this.consignmentId, this.previewOnly, this.selectedCertificateNumber.Code, Enum.EnumModelType.ExportConsignment, this.reportId, null).then(() => {

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });

                    });
            }


        }

        angular.module("app").controller("certificateFromRegisterCtrl", controllers.reporting.certificateFromRegisterCtrl);
    }
}